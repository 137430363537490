// Plugins
import 'slick-carousel'


// Passive listeners
jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
  }
};


// Slick slider for 'testimonials-slider'
$(".testimonials-slider").slick({
  infinite: !0,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: !0,
  autoplaySpeed: 8e3,
  dots: !0,
  arrows: !0,
  prevArrow: $('.prev'),
  nextArrow: $('.next')
});


// Main menu hamburger
$("#main-menu-hamburger").on("click", function() {
  $("html, body").toggleClass("overflow-clip");
  $("#main-nav").toggleClass("!left-0").toggleClass("opacity-100");
  $("#top-bar, #main-menu-hamburger div").toggleClass("open");
});


// Main menu hamburger
$("#main-nav a, #main-logo, #main-logo-light").on("click", function() {
  $("html, body").removeClass("overflow-clip");
  $("#main-nav").removeClass("!left-0").removeClass("opacity-100");
  $("#top-bar, #main-menu-hamburger div").removeClass("open");
});


// Dynamic document download links based on dropdown selection
$("#document-policy-source").on("change", function() {
  $(this).find("option:selected").each(function() {
    var optionValue = $(this).attr("value");
    if(optionValue){
      $(".document-download-links").not("#" + optionValue).hide();
      $("#" + optionValue).show();
    } else{
      $(".document-download-links").hide();
    }
  });
}).trigger("change");